import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#e4d8ab',
		},
		secondary: {
			main: '#444444',
		},
		background: {
			default: '#eeeeee',
			paper: '#ffffff',
		},
		error: {
			main: '#d50000',
		},
		warning: {
			main: '#d81b60',
		},
		info: {
			main: '#0277bd',
		},
		success: {
			main: '#689f38',
		},
	
	},
	typography: {
		fontFamily: 'Asap',
		h1: {
			fontSize: '3.3rem',
			fontWeight: 700,
			fontFamily: 'Asap ',
		},
		body1: {
			fontFamily: 'Playfair Display',
			fontSize: '1.0625rem',
			lineHeight: '1.55em',
			fontWeight: '300',
		},
		h1: {
			fontSize: '3rem',
			lineHeight: '1.3em',
			fontWeight: '700',
		},
		h2: {
			fontFamily: 'Asap',
		},
		h3: {
			fontSize: '1.5625rem',
			lineHeight: '1.4em',
			fontWeight: '700',
		},
		h4: {
			fontSize: '1.125rem',
			lineHeight: '1.5em',
			fontWeight: '700',
			margin: '2rem 0 1rem',
		},
		body2: {
			fontFamily: 'Playfair Display',
		},
		button: {
			fontFamily: 'Playfair Display',
		},
		caption: {
			fontFamily: 'Playfair Display',
		},
		overline: {
			fontFamily: 'Playfair Display',
		},
	},
	shape: {
		borderRadius: 0,
	},
	components: {
		// MuiCssBaseline: {
		// 	styleOverrides: {
		// 		body: {
		// 			width: '100%',
		// 			overflowX: 'hidden',
		// 			overflowY: 'hidden',
		// 		},
		// 	},
		// },
		MuiTypography: {
			styleOverrides: {
				// root: {
				// 	textTransform: 'uppercase',
				// },
				body1: {
					textTransform: 'none',
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'aleign' },
					style: {
						fontWeight: 700,
						fontSize: '12px',
						padding: '12px 40px',
						margin: '.3125rem 1px',
						lineHeight: '20px',
						letterSpacing: '.2em',
						border: '2px solid transparent',
						outline: '1px solid transparent',
						transition: 'all .5s ease .25s',
						minWidth: '160px',
						'&:hover': {
							borderColor: 'white',
						},
						'&:after, &:before': {
							transition: 'all .2s ease 0s',
							content: "''",
							position: 'absolute',
							width: '2px',
							height: '100%',
							top: 0,
							background: 'white',
						},
						'&:before': {
							left: '-8px',
						},
						'&:hover:before': {
							left: -2,
						},
						'&:after': {
							right: '-8px',
						},
						'&:hover:after': {
							right: -2,
						},
						'&:hover,&:focus,&:active,&.active': {
							color: '#333',
							backgroundColor: '#e6e6e6',
							borderColor: 'white',
						},
					},
				},
				{
					props: { variant: 'jesus' },
					style: { textTransform: 'none' },
				},
			],
		},
	},
});

export default theme;
