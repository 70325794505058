import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../src/theme';
import useSiteMetadata from '../../src/components/SiteMetadata';

import { withPrefix } from 'gatsby';

export default function TopLayout(props) {
	const { title, description } = useSiteMetadata();
	return (
		<React.Fragment>
			<Helmet>
				<meta name='viewport' content='initial-scale=1, width=device-width' />
				<link rel='preconnect' href='https://fonts.googleapis.com' />
				<link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
				<link
					href="https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,600;1,100;1,600&family=Playfair+Display:ital,wght@0,400;0,800;1,400;1,800&display=swap"
					rel='stylesheet'
				/>
				<html lang='en' />
				<title>{title}</title>
				<meta name='description' content={description} />

				<link
					rel='apple-touch-icon'
					sizes='180x180'
					href={`${withPrefix('/')}img/apple-touch-icon.png`}
				/>
				<link
					rel='icon'
					type='image/png'
					href={`${withPrefix('/')}img/favicon-32x32.png`}
					sizes='32x32'
				/>
				<link
					rel='icon'
					type='image/png'
					href={`${withPrefix('/')}img/favicon-16x16.png`}
					sizes='16x16'
				/>

				<link
					rel='mask-icon'
					href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
					color='#ff4400'
				/>
				<meta name='theme-color' content='#fff' />

				<meta property='og:type' content='business.business' />
				<meta property='og:title' content={title} />
				<meta property='og:url' content='/' />
				<meta property='og:image' content={`${withPrefix('/')}img/og-image.jpg`} />

				{/* freshdesk chatbot --- moved to -ssr file for testing. this works here but does it also work in ssr */}
				{/* <script type='text/javascript'>
					{`(function(d, w, c) {
							if (!d.getElementById("spd-busns-spt")) {
									var n = d.getElementsByTagName('script')[0],
											s = d.createElement('script');
									var loaded = false;
									s.id = "spd-busns-spt";
									s.async = "async";
									s.setAttribute("data-self-init", "false");
									s.setAttribute("data-init-type", "opt");
									s.src = 'https://cdn.au-freshbots.ai/assets/share/js/freshbots.min.js';
									s.setAttribute("data-client", "e82535eaa1be467acb91f29f984a470deb4a3887");
									s.setAttribute("data-bot-hash", "35843a6c2bf113ff6c5fdcd3b67888eb31416dff");
									s.setAttribute("data-env", "prod");
									s.setAttribute("data-region", "au");
									if (c) {
											s.onreadystatechange = s.onload = function() {
													if (!loaded) {
															c();
													}
													loaded = true;
											};
									}
									n.parentNode.insertBefore(s, n);
							}
					})
					(document, window, function() {
							Freshbots.initiateWidget({
											autoInitChat: false,
											getClientParams: function() {
													return;
											}
									},
									function(successResponse) {},
									function(errorResponse) {});
									FreshworksWidget('hide', 'launcher');
					});`}
				</script> */}
			</Helmet>
			<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				<GlobalStyles styles={{ html: { scrollBehavior: 'smooth' } }} />
				{props.children}
			</ThemeProvider>
		</React.Fragment>
	);
}

TopLayout.propTypes = {
	children: PropTypes.node,
};
